<template>
  <v-dialog justify="center" :value="value" max-width="300" persistent>
    <v-card color=#fafafa>
      <section class="ma-0 pa-6">
        <v-row dense justify="center">
          <b>{{`${payload.message}...Please Wait`}}</b>
        </v-row>
        <v-row dense justify="center">
          <v-progress-circular size="50" color="primary" indeterminate/>
        </v-row>
      </section>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'Loader',

  props: ['value', 'payload']
}
</script>
