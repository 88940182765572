var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: {
        justify: "center",
        value: _vm.value,
        "max-width": "300",
        persistent: ""
      }
    },
    [
      _c("v-card", { attrs: { color: "#fafafa" } }, [
        _c(
          "section",
          { staticClass: "ma-0 pa-6" },
          [
            _c("v-row", { attrs: { dense: "", justify: "center" } }, [
              _c("b", [_vm._v(_vm._s(_vm.payload.message + "...Please Wait"))])
            ]),
            _c(
              "v-row",
              { attrs: { dense: "", justify: "center" } },
              [
                _c("v-progress-circular", {
                  attrs: { size: "50", color: "primary", indeterminate: "" }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }